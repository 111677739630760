import './Header.css';

function Header(props) {
    return (
        <div className='HeaderContainer'>
            <div className='HeaderLogo'>
                <img className='HeaderLogoImage' src="HeddaLogoNormalBlack.svg" alt="Logo"/>
                </div>
            <div className='NavBar'>
                <a href='#getstarted'>Get started</a>
                <a href='#features'>Our solution</a>
                <a href='#safety'>Safety</a>
            </div>
        </div>
    )
}

export default Header;