import './Chat.css';

function Chat() {
    return (
        <div className='ChatContainer '>
            <div className='ChatSubContainerLeft'>
            <div className='ChatTextContainer'>
                    <p className='ChatIntroductionText'>Chat with the procurement documents</p>
                    <p className='ChatHeaderText'>Make it <span style={{color: '#0075FF'}}>easy</span> to understand the procurement</p>
                    <p className='ChatDescriptionText'>Find exactly the information you're looking for - whether it's procurement documents or your internal knowledge base. Our AI quickly searches for relevant answers, references the right documents and marks exactly where the information is located. This way, you can be sure to get accurate and reliable answers to all your questions. </p>
                </div>
            </div>
            <div className='ChatSubContainerRight'>
                
                <img src={`${process.env.PUBLIC_URL}/chat.png`} alt='placeholder' className='ChatImage' />
   
            </div>

        </div>
    )
}


export default Chat;