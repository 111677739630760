import './BidPlatform.css';

function BidPlatform() {
    return (
        <div className='BidPlatformContainer '>
            <div className='BidPlatformSubContainerLeft'>
                <div className='BidPlatformTextContainer'>
                    <p className='BidPlatformIntroductionText'>Our platform</p>
                    <p className='BidPlatformHeaderText'>Your <span style={{color: '#0075FF'}}>new</span> bidspace.</p>
                    <p className='BidPlatformDescriptionText'>The tendering process is often time-consuming and complex. We make it easier by preparing drafts, identifying relevant information and reviewing your responses. In addition, we offer project management tools to facilitate your collaboration. With Hedda, you can simplify your tendering process and deliver high-quality tenders faster and more efficiently. </p>
                </div>
            </div>
            <div className='BidPlatformSubContainerRight'>
                <iframe
                    
                    src="https://www.youtube.com/embed/9OOgMuEiYHM?rel=0&modestbranding=1&showinfo=0&autohide=1&controls=1"
                   
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className='BidPlatformVideo'
                ></iframe>
            </div>

        </div>
    )
}


export default BidPlatform;