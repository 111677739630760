import { useState } from 'react';
import { Modal, Button, Input, Textarea } from '@mantine/core';

function ModalComponent(props) {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        website: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Modal opened={props.opened} onClose={props.close} title="Book Demo">
            <form action="https://formspree.io/f/mnnargdj" method="POST">
                <Input.Wrapper label="First name" required>
                    <Input 
                        name="firstName" 
                        placeholder="Erik" 
                        value={formData.firstName} 
                        onChange={handleChange} 
                        required 
                    />
                </Input.Wrapper>
                <Input.Wrapper label="Surname" required>
                    <Input 
                        name="lastName" 
                        placeholder="Eriksson" 
                        value={formData.lastName} 
                        onChange={handleChange} 
                        required 
                    />
                </Input.Wrapper>
                <Input.Wrapper label="Email" required>
                    <Input 
                        name="email" 
                        type="email" 
                        placeholder="hej@hedda.ai" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                    />
                </Input.Wrapper>
                <Input.Wrapper label="Website" required>
                    <Input 
                        name="website" 
                        placeholder="www.hedda.ai" 
                        value={formData.website} 
                        onChange={handleChange} 
                        required 
                    />
                </Input.Wrapper>
                <Input.Wrapper label="Help us prepare">
                    <Textarea 
                        name="message" 
                        placeholder="We leave ... RFPs yearly, and currently has issues with ..." 
                        value={formData.message} 
                        onChange={handleChange} 
                    />
                </Input.Wrapper>
                <Button type="submit" variant="filled" style={{marginTop:'12px'}}>Send</Button>
            </form>
        </Modal>
    );
}

export default ModalComponent;
