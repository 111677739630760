import './Safety.css';
import FeatureShowcase from '../../components/FeatureShowcase/FeatureShowcase';
import { IconShieldLockFilled, IconUsers, IconUserCheck, IconHandOff, IconLockFilled } from '@tabler/icons-react';


function Safety() {
  return (
    <div className='SafetySegmentContainer' id="safety">
      <p className='SafetyHeaderText'>We take <span style={{ color: '#0075FF' }}>safety</span> seriously</p>
      <p className='SafetyIntroductionText'>A RFP tool you can rely on</p>
      <div className='SafetyContainer'>
        <FeatureShowcase
          icon={<IconShieldLockFilled size={36} color='black' />}
          title="Encrypted"
          text="AES-256 encryption is used for data at rest and TLS 1.2 is used for data in transit."
        />

        <FeatureShowcase
          icon={<IconUsers size={36} color='black' />}
          title="In accordance with GDPR"
          text="Hedda complies with GDPR and works according to the CIS framework. No data leaves the EU."
        />

        <FeatureShowcase
          icon={<IconUserCheck size={36} color='black' />}
          title="No training on data"
          text="None of your data is used to train our models. Just as it sounds, your data is just that, yours."
        />

        <FeatureShowcase
          icon={<IconHandOff size={36} color='black' />}
          title="Operational safety"
          text="Protects against malware of the highest standard, as well as confidentiality agreements with staff, customers and suppliers."
        />

      </div>
    </div>
  )

}

export default Safety;