import './Features.css';
import GPTAnswer from '../../components/GPTAnswer/GPTAnswer';
function Features() {


    return (
        <div className='FeatureContainer' id="features">
            <div className="FeatureTextContainer">
                <p className='FeatureTextTitle'>RFPs <span style={{ color: '#0075FF' }}>without</span> headaches</p>
                <p className='FeatureTextSubtitle'>Writing support that allows you to spend your time on what really matters</p>
            </div>
            <div className="FeatureBoxesContainer">
                <div className="UpperBoxes">
                    <div className="UpperLeftBox">
                        <p className="UpperBoxTitle">AI-generation</p>
                        <GPTAnswer answer='50-70% of the questions in many tenders have already been answered in previous bids. With Hedda, you can sit back and let our AI take care of them. Our advanced AI writes tailored answers based on requirements and questions, while integrating content from your own documents. Developed specifically for bidding teams, Hedda helps you produce bids in no time.' />

                    </div>
                    <div className="UpperRightBox">
                        <p className="UpperBoxTitle">References</p>
                        <p className="UpperBoxText">All material produced by our AI is referenced. You can see which documents and paragraphs form the basis of the answers, so you can feel confident.</p>
                        <img className="GifImage" src={`${process.env.PUBLIC_URL}/scource.gif`} alt="Källhänvisning GIF" />
                    </div>
                </div>
                <div className="LowerBoxes">
                    <div className="LowerLeftBox">
                        <div>
                            <p className="LowerBoxTitle">AI-edit</p>
                            <p className="LowerBoxText">Edit written text effectively, with handy and useful tools.</p>
                        </div>
                        <img className="BottomImg" src={`${process.env.PUBLIC_URL}/editNew.svg`} alt="Chatta med upphandlingsdokumenten GIF" />
                    </div>
                    <div className="LowerMiddleBox">
                        <div>
                            <p className="LowerBoxTitle">Save time</p>
                            <p className="LowerBoxText">Get feedback on how to improve your answers and avoid common mistakes.</p>
                        </div>
                        <img className="BottomImg" src={`${process.env.PUBLIC_URL}/comment.png`} alt="Spara tid GIF" />
                    </div>
                    <div className="LowerRightBox">
                        <div>
                            <p className="LowerBoxTitle">Stress-free administration</p>
                            <p className="LowerBoxText">Assign questions, set reviewers and see the status of the tender. We make tender administration a breeze.</p>
                        </div>
                        <img className="BottomImg" src={`${process.env.PUBLIC_URL}/admin.png`} alt="Spara tid GIF" />

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Features;