import './HeroSection.css';
import ShortcutButton from '../../components/ShortcutButton/ShortcutButton';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import { useDisclosure } from '@mantine/hooks';

function HeroSection() {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <div className="HeroSectionContainer" id="getstarted">
            <ModalComponent opened={opened} close={close} />
            <div className="HeroSectionContent">
                <div className="HeroSectionTextButtonContainer">
                    
                        <p className="HeroSectionTextTitle"><span style={{ color: '#0075FF' }}>Streamline</span> your RFP writing</p>
                        <p className="HeroSectionText">Get started for free</p>
                    
                    <div className="HeroSectionButtonContainer">
                        <ShortcutButton text="Book a demo" onClick={open} />
                    </div>
                </div>

            </div>

        </div>
    );
}

export default HeroSection;